import React, { useState, useEffect, useRef, FC } from 'react';
import '../../../styles/src/window/index.scss';
import { ArrowsIn, ArrowsOut, X as IconX } from '@phosphor-icons/react'

interface WindowProps {
  id: number;
  title: string;
  url: string;
  onClose: (id: number) => void;
  onToggleMinimize: (id: number, isMinimized: boolean) => void;
}

const TestWindow: FC<WindowProps> = ({ id, title, url, onClose, onToggleMinimize }) => {
  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  const [initialWidth, translateTop]: [number, number] = (() => {
    if (isMobile) return [window.innerWidth, -16];
    const _1perc = parseInt(String(window.innerWidth / 100));
    return [_1perc * 80, _1perc * 10];
  })()
  const [initialHeight, translateLeft]: [number, number] = (() => {
    if (isMobile) return [window.innerHeight, -16];
    const _1perc = parseInt(String(window.innerHeight / 100));
    return [_1perc * 80, _1perc * 10];
  })();
  console.log(window.innerWidth, initialWidth, translateLeft);
  console.log(window.innerHeight, initialHeight, translateTop);
  console.log(`\n\n\n`)
  const [content, setContent] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [isFullscreen, setIsFullscreen] = useState<boolean>(false);
  const [isMinimized, setIsMinimized] = useState<boolean>(false);
  const windowRef = useRef<HTMLDivElement | null>(null);
  const positionRef = useRef<{ x: number; y: number }>({
    x: (isFullscreen || isMobile) && !isMinimized
      ? 0
      : translateTop,
    y: ((isFullscreen || isMobile) && !isMinimized)
      ? 0
      : translateLeft
  });
  const sizeRef = useRef<{ width: number; height: number }>({ width: isFullscreen && !isMinimized ? window.innerWidth : initialWidth, height: isFullscreen && !isMinimized ? window.innerHeight - 40 : initialHeight });
  const dragging = useRef<boolean>(false);
  const resizing = useRef<boolean>(false);

  useEffect(() => {
    if (url) {
      setLoading(true);
      fetch(url)
        .then(response => response.json())
        .then(data => {
          setContent(data.modal);
        })
        .catch(() => {
          setContent('Errore durante il caricamento');
        }).finally(() => {
          setLoading(false);
        });
    }
  }, [url]);


  useEffect(() => {
    if (windowRef.current) {
      const resizeObserver = new ResizeObserver((entries) => {
        for (let entry of entries) {
          const { width } = entry.contentRect;
          if (!windowRef.current) return;

          // Rimuove le classi esistenti
          windowRef.current.classList.remove('ws-w-xs', 'ws-w-sm', 'ws-w-md', 'ws-w-lg', 'ws-w-xl', 'ws-w-xxl');

          // Aggiunge solo una classe in base alla larghezza
          if (width >= 1400) {
            windowRef.current.classList.add('ws-w-xxl');
            windowRef.current.setAttribute('include', 'xxl');
            windowRef.current.setAttribute('exclude', 'xs sm md lg xl');
          } else if (width >= 1200) {
            windowRef.current.classList.add('ws-w-xl');
            windowRef.current.setAttribute('include', 'xl');
            windowRef.current.setAttribute('exclude', 'xs sm md lg xxl');
          } else if (width >= 992) {
            windowRef.current.classList.add('ws-w-lg');
            windowRef.current.setAttribute('include', 'lg');
            windowRef.current.setAttribute('exclude', 'xs sm md xl xxl');
          } else if (width >= 768) {
            windowRef.current.classList.add('ws-w-md');
            windowRef.current.setAttribute('include', 'md');
            windowRef.current.setAttribute('exclude', 'xs sm lg xl xxl');
          } else if (width >= 576) {
            windowRef.current.classList.add('ws-w-sm');
            windowRef.current.setAttribute('include', 'sm');
            windowRef.current.setAttribute('exclude', 'xs md lg xl xxl');
          } else {
            windowRef.current.classList.add('ws-w-xs');
            windowRef.current.setAttribute('include', 'xs');
            windowRef.current.setAttribute('exclude', 'sm md lg xl xxl');
          }
        }
      });

      resizeObserver.observe(windowRef.current);

      return () => {
        resizeObserver.disconnect();
      };
    }
  }, []);

  const handleFullscreen = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setIsFullscreen(!isFullscreen);
  };

  const handleMinimize = () => {
    setIsMinimized(!isMinimized);
    onToggleMinimize(id, !isMinimized);
  };

  const handleDestroy = () => {
    onClose(id);
  };

  const handleMouseDown = (e: React.MouseEvent | React.TouchEvent<HTMLDivElement>) => {
    if(isFullscreen || isMinimized) return;
    dragging.current = true;
    const startX = 'clientX' in e ? e.clientX : e.touches[0].clientX;
    const startY = 'clientY' in e ? e.clientY : e.touches[0].clientY;
    const { x, y } = positionRef.current;

    const handleMouseMove = (moveEvent: MouseEvent | TouchEvent) => {
      if (!dragging.current) return;
      const newX = x + ((moveEvent instanceof MouseEvent ? moveEvent.clientX : moveEvent.touches[0].clientX) - startX);
      const newY = y + ((moveEvent instanceof MouseEvent ? moveEvent.clientY : moveEvent.touches[0].clientY) - startY);
      positionRef.current = { x: newX, y: newY };
      if (windowRef.current) {
        windowRef.current.style.transform = `translate(${newX}px, ${newY}px)`;
      }
    };

    const handleMouseUp = () => {
      dragging.current = false;
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
      window.removeEventListener('touchmove', handleMouseMove);
      window.removeEventListener('touchend', handleMouseUp);
    };

    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('mouseup', handleMouseUp);
    window.addEventListener('touchmove', handleMouseMove);
    window.addEventListener('touchend', handleMouseUp);
  };

  const handleFullscreenDoubleClick = () => {
    setIsFullscreen(!isFullscreen);
  };

  const handleResizeMouseDown = (e: React.MouseEvent | React.TouchEvent<HTMLDivElement>, direction: string) => {
    if(isFullscreen || isMinimized) return;
    resizing.current = true;
    const startX = 'clientX' in e ? e.clientX : e.touches[0].clientX;
    const startY = 'clientY' in e ? e.clientY : e.touches[0].clientY;
    const { width, height } = sizeRef.current;
    const { x, y } = positionRef.current;

    const handleMouseMove = (moveEvent: MouseEvent | TouchEvent) => {
      if (!resizing.current) return;
      let newWidth = width;
      let newHeight = height;
      let newX = x;
      let newY = y;

      if (direction.includes('right')) {
        newWidth = width + ((moveEvent instanceof MouseEvent ? moveEvent.clientX : moveEvent.touches[0].clientX) - startX);
      }
      if (direction.includes('bottom')) {
        newHeight = height + ((moveEvent instanceof MouseEvent ? moveEvent.clientY : moveEvent.touches[0].clientY) - startY);
      }
      if (direction.includes('left')) {
        newWidth = width - ((moveEvent instanceof MouseEvent ? moveEvent.clientX : moveEvent.touches[0].clientX) - startX);
        newX = x + ((moveEvent instanceof MouseEvent ? moveEvent.clientX : moveEvent.touches[0].clientX) - startX);
      }
      if (direction.includes('top')) {
        newHeight = height - ((moveEvent instanceof MouseEvent ? moveEvent.clientY : moveEvent.touches[0].clientY) - startY);
        newY = y + ((moveEvent instanceof MouseEvent ? moveEvent.clientY : moveEvent.touches[0].clientY) - startY);
      }

      sizeRef.current = { width: newWidth, height: newHeight };
      positionRef.current = { x: newX, y: newY };
      if (windowRef.current) {
        windowRef.current.style.width = `${newWidth}px`;
        windowRef.current.style.height = `${newHeight}px`;
        windowRef.current.style.transform = `translate(${newX}px, ${newY}px)`;
      }
    };

    const handleMouseUp = () => {
      resizing.current = false;
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
      window.removeEventListener('touchmove', handleMouseMove);
      window.removeEventListener('touchend', handleMouseUp);
    };

    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('mouseup', handleMouseUp);
    window.addEventListener('touchmove', handleMouseMove);
    window.addEventListener('touchend', handleMouseUp);
  };

  const handleActivate = () => {
    if (windowRef.current) {
      document.querySelectorAll('.athx-window').forEach((_win: Element) => {
        if (_win !== windowRef.current) {
          _win.classList.remove('active');
          (_win as HTMLElement).style.zIndex = '0';
        }
      });
      windowRef.current.classList.add('active');
      windowRef.current.style.zIndex = '1';
    }
  };

  return (
    <div
      ref={windowRef}
      className={`athx-window ${isFullscreen ? 'fullscreen' : ''} ${isMinimized ? 'minimized' : ''}`}
      style={{
        transform: `translate(${positionRef.current.x}px, ${positionRef.current.y}px)`,
        width: sizeRef.current.width,
        height: sizeRef.current.height,
      }}
      onMouseDown={handleActivate}
      onTouchStart={handleActivate}
      onDoubleClick={handleFullscreenDoubleClick}
    >
      <div className="athx-window-resizer t" onMouseDown={(e) => handleResizeMouseDown(e, 'top')} onTouchStart={(e) => handleResizeMouseDown(e, 'top')}></div>
      <div className="athx-window-header" onMouseDown={handleMouseDown} onTouchStart={handleMouseDown}>
        <h4 className="athx-window-title">{title}</h4>
        <div className="athx-window-actions">
          <button className="athx-window-action m" onClick={handleMinimize}>_</button>
          <button className={`athx-window-action f ${isFullscreen ? 'active' : ''}`} onClick={handleFullscreen}>{isFullscreen ? <ArrowsIn size={24} /> : <ArrowsOut size={24} />}</button>
          <button className="athx-window-action d" onClick={handleDestroy}>X</button>
        </div>
      </div>
      {!isMinimized && (
        <div className="athx-window-body">
          <div className="athx-window-resizer l" onMouseDown={(e) => handleResizeMouseDown(e, 'left')} onTouchStart={(e) => handleResizeMouseDown(e, 'left')}></div>
          <div className="athx-window-content-container">
            <div className="athx-window-menu">

            </div>
            {loading ? (
              <div className="loading-overlay">
                <span>Caricamento in corso...</span>
              </div>
            ) : (
              <div className="athx-window-content" dangerouslySetInnerHTML={{ __html: content || '' }} />
            )}
          </div>
          <div className="athx-window-resizer r" onMouseDown={(e) => handleResizeMouseDown(e, 'right')} onTouchStart={(e) => handleResizeMouseDown(e, 'right')}></div>
        </div>
      )}
      <div className="athx-window-footer"></div>
      <div className="athx-window-resizer b" onMouseDown={(e) => handleResizeMouseDown(e, 'bottom')} onTouchStart={(e) => handleResizeMouseDown(e, 'bottom')}></div>
      <div className="athx-window-resizer a-tl" onMouseDown={(e) => handleResizeMouseDown(e, 'top-left')} onTouchStart={(e) => handleResizeMouseDown(e, 'top-left')}></div>
      <div className="athx-window-resizer a-tr" onMouseDown={(e) => handleResizeMouseDown(e, 'top-right')} onTouchStart={(e) => handleResizeMouseDown(e, 'top-right')}></div>
      <div className="athx-window-resizer a-bl" onMouseDown={(e) => handleResizeMouseDown(e, 'bottom-left')} onTouchStart={(e) => handleResizeMouseDown(e, 'bottom-left')}></div>
      <div className="athx-window-resizer a-br" onMouseDown={(e) => handleResizeMouseDown(e, 'bottom-right')} onTouchStart={(e) => handleResizeMouseDown(e, 'bottom-right')}></div>
    </div>
  );
};

export default TestWindow;