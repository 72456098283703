import React from "react";
import "../../styles/src/footer/index.scss";
import { LeftMenu } from "./src/LeftMenu";
import { CenterMenu } from "./src/CenterMenu";
import { RightMenu } from "./src/RightMenu";
export function Footer() {
  
  return <div id="footer">
    <LeftMenu></LeftMenu>
    <CenterMenu></CenterMenu>
    <RightMenu></RightMenu>
  </div>
}