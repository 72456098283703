import React, { useEffect } from 'react';
import '../../styles/index.scss';
import { Desktop } from '../desktop';
import { Footer } from '../footer';
import { AppMenu } from '../menus/app';
import { MenuProvider } from '../menus';
import { SearchMenu } from '../menus/search';
import { NotificationsMenu } from '../menus/notifications';
import { MessagesMenu } from '../menus/messages';
import { EmailsMenu } from '../menus/emails';
import { TasksMenu } from '../menus/tasks';
import { CalendarMenu } from '../menus/calendar';
import { MiniMenu } from '../menus/mini';
import {SocketNotification} from './worker'


const Root: React.FC = () => {
  useEffect(() => {
    const appName = process.env.REACT_APP_NAME || 'undefined';
    if (!appName)
      console.error('REACT_APP_APP_NAME is not defined in .env file');
    document.title = appName;
  }, []);

  useEffect(() => {
    // Disabilita il comportamento di pull-to-refresh sui dispositivi touch
    const disablePullToRefresh = (e: TouchEvent) => {
      if (e.touches.length > 1 || e.changedTouches[0].clientY > 0) {
        e.preventDefault();
      }
    };

    const disableContextMenu = (e: MouseEvent) => {
      if (process.env.REACT_APP_ENVIROMENT !== 'development')
        e.preventDefault();
    };

    document.addEventListener('touchmove', disablePullToRefresh, { passive: false });
    document.addEventListener('contextmenu', disableContextMenu);

    return () => {
      document.removeEventListener('touchmove', disablePullToRefresh);
      document.removeEventListener('contextmenu', disableContextMenu);
    };
  }, []);


  return (
    
    <div>
      <SocketNotification></SocketNotification>
      <Desktop></Desktop>
      <MenuProvider>
        <AppMenu></AppMenu>
        <SearchMenu></SearchMenu>
        <NotificationsMenu></NotificationsMenu>
        <MessagesMenu></MessagesMenu>
        <EmailsMenu></EmailsMenu>
        <TasksMenu></TasksMenu>
        <CalendarMenu></CalendarMenu>
        <MiniMenu></MiniMenu>
        <Footer></Footer>
      </MenuProvider>
    </div>
  );
}

export default Root;
